import Api from '@/utils/request'

export default {
  methods: {
    init(isMobile = false) {
      this.impressionUUID = this.Helper.generateUUID();
      let slug = this.$store.state.routerParams[0]
      Api.get(`/landing-pages/${slug}`)
        .then(({ data }) => {
          this.title = data.title
          this.subtitle = data.subtitle
          this.bannerImageUrl = isMobile ? data.bannerMobileImageUrl : data.bannerImageUrl
          this.sections = data.sections
          const gigIds = []
          this.sections.forEach(section => {
            if (section.type === 'likeList') {
              section.entities.forEach(gig => {
                gigIds.push(gig.id)
              })
            }
          })

          this.$store.state.indexHTMLTitle = data.title + ' - Bionluk Koleksiyon';
          this.$store.state.indexHTMLMetaDescription = data.title;

          this.Helper.trackEvents.pageView({ title:data.title});

          return gigIds
        })
        .then(gigIds => {
          return this.api.general.getGigLikesV2(gigIds, 'null', '',  '')
        })
        .then(({data}) => {
          let likeResult = data
          this.sections.forEach(section => {
            if (section.type === 'likeList') {
              section.entities = section.entities.map(gig => {
                let foundLike = likeResult.data.likeStatuses.find(({ id }) => id === String(gig.id))
                if(foundLike){
                  gig.likeStatus = foundLike.likeStatus
                }
                return gig
              })
            }
            return section
          })
        })
        .catch(async err => {
          if (err.status === 404) {
            this.$toasted.global.errorToast({ description: 'Sayfa bulunamadı' })
            await this.$router.push('/')
          }
        })
        .finally(() => {
          this.pageLoaded = true
        })
    }
  }
}
